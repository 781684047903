import * as React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import { Cloudinary } from '@cloudinary/url-gen';

import {Helmet} from 'react-helmet';
import { siteMetadata } from '../../gatsby-config';

import LoadingScreen from '../components/Misc/ScreenLoading';

const cld = new Cloudinary({
    cloud: {
        cloudName: siteMetadata.cloudinaryContextCloudName
    }
});

const importSection = (sectionCategory, sectionName) => {

    return React.lazy(() =>
        import(`../sections/${sectionCategory}/${sectionName.split(' ').join('')}/${sectionName.split(' ').join('')}`).catch((err) => console.log(err.message) )
    )
}

const FrontendPage = ({ data, pageContext }) => {

    let pageName = pageContext.PageName;

    const [children, setChildren] = React.useState([]);


    React.useEffect(() => {

        async function loadSections() {

            const getSections  = (sectionArray, pageElements = null) => {
                return sectionArray.map(async (section, index) => {
                    const Element = await importSection(section.category, section.name);
                    return <Element 
                                key={index}
                                name={section.name} 
                                SectionSettings={JSON.parse(section.settings)} 
                                SiteSettings={JSON.parse(data.datoCmsWebsite.settings)}
                                SectionMenus={section.menus}
                                children={data.datoCmsPage.name == "PageWrapper" ? pageElements : null}
                                pageContext={pageContext}
                            />
                })
            }

            const recursivlyAggregateSections = (primarySections, innerSections = null) => {
                
                let aggregatedSections = primarySections.map( section => {
                    if (section.name.split(' ').join('') == "PageWrapper") {
                        return recursivlyAggregateSections(innerSections)
                    }

                    return section;
                })

                return aggregatedSections;
            }

            let sectionPromises;

            // FIRST: decide if we should be using attatched template sections in the page
            // SECOND: fetch sections from data, iterate throught each to generate a promise
            // that will reolve to a react element derived from the respective section import,
            // and return an element for each
            if (data.datoCmsPage.template !== null) {
                if (data.datoCmsPage.template.sections.filter( section => section.name.split(' ').join('') == "PageWrapper").length > 0) {
                    let aggregatedSections = recursivlyAggregateSections(data.datoCmsPage.template.sections, data.datoCmsPage.sections);
                    aggregatedSections = aggregatedSections.flat(1);
                    sectionPromises = await getSections(aggregatedSections);
                } else {
                    sectionPromises = [];
                }
            } else {
                sectionPromises = await getSections(data.datoCmsPage.sections);
            }
            
            // after all import promises have resolved, affix the elements to the local state
            // so the elements can render themselves

            Promise.all(sectionPromises).then( async elements => {
               
                setChildren(elements)
            });
        }

        loadSections();
    }, []);

    const siteLogo = cld.image(`${JSON.parse(data.datoCmsWebsite.settings).siteLogo?.name}`).toURL();

    return (
        <div>
            <Helmet>
                <html lang="en" />

                <link rel="icon" href={siteLogo} />

                <meta charset="utf-8" />
                <title>{data.datoCmsPage.name}</title>
                <link rel="canonical" href={`${JSON.parse(data.datoCmsWebsite.settings).domainName}${siteMetadata.homePage}`} />
                <meta name="description" content={JSON.parse(data.datoCmsWebsite.settings).siteDescription.value} />
                <meta name="robots" content="index, follow" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />

                {Object.keys(JSON.parse(data.datoCmsWebsite.settings).social).map( (socialNetwork, index) => {
                    if (JSON.parse(data.datoCmsWebsite.settings).social[socialNetwork].value.length > 0) return (
                        <link key={index} rel="dns-prefetch" href={JSON.parse(data.datoCmsWebsite.settings).social[socialNetwork].value} />
                    )
                })}
            </Helmet>
            <React.Suspense 
                fallback={<LoadingScreen 
                            publicId={JSON.parse(data.datoCmsWebsite.settings).siteLogo?.name} 
                            color={JSON.parse(data.datoCmsWebsite.settings).colors.primary}
                        />} 
            >
                {children}
            </React.Suspense>
        </div>
    )
}


export const query = graphql`
    query PageQuery($PageName: String, $sitename: String) {
        datoCmsPage(name: { eq: $PageName }, site: {name: {eq: $sitename }}) {
            id
            name
            template {
                sections {
                    id
                    name
                    category
                    settings
                }
            }
            sections {
                id
                name
                category
                settings
                menus {
                    id
                    name
                    links 
                }
            }
        }
        datoCmsWebsite(name: {eq: $sitename}) {
            settings
        }
    }
`;

export default FrontendPage;